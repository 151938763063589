<template>
  <div>
    <TitleWrapper
      :filter-option="false"
      title="SYLLABUS_TEMP_IMPORT"
      tooltip-title="SYLLABUS_TEMP_IMPORT"
      :display-breadcrumb="true"
    />

    <!-- Subjects -->
    <template v-if="isDataLoading">
      <Loader class="mt-20 mb-40" :content="true" />
    </template>
    <div v-else>
      <DetailsCard class="my-5" :details="templateDetails" custom-classes="flex-col" />
      <!-- Chapters -->
      <div class="flex justify-between items-center">
        <Heading heading="Chapters" />
      </div>
      <template v-if="isChaptersDataLoading">
        <Loader class="mt-20 mb-40" :content="true" />
      </template>
      <div v-else-if="!isChaptersDataLoading && isEmpty(currentChaptersList)">
        <NoRecordFound />
      </div>
      <div v-else class="bg-white my-5 relative rounded-lg border border-primary-grey">
        <div
          id="table-container"
          :class="[leftBlurArea, rightBlurArea]"
          class="overflow-x-auto rounded-lg"
          @scroll.passive="scrollDetect"
        >
          <div>
            <table class="w-full table-auto whitespace-nowrap">
              <thead class="bg-bright-gray">
                <tr class="text-left">
                  <th
                    v-for="head in chaptersHeaders"
                    :key="head"
                    class="ltr:border-r border-primary-grey py-5 rtl:text-right pl-4 pr-2.5"
                  >
                    <span class="flex items-center gap-1">
                      {{ head }}
                    </span>
                  </th>
                  <th class="ltr:border-r border-primary-grey py-5 rtl:text-right pl-4 pr-2.5">
                    <span>
                      <UiCheckbox
                        :key="`chapter${isAllChaptersSelected}`"
                        :no-error="true"
                        class="pl-1"
                        :model-value="isAllChaptersSelected"
                        @change="markAllChaptersSelected($event)"
                      />
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(chapter, index) in currentChaptersList"
                  :key="`index-${index}`"
                  class="border-t border-primary-grey text-left"
                >
                  <td
                    class="ltr:border-r rtl:border-l border-primary-grey py-5 rtl:text-right pl-4 pr-2.5"
                  >
                    <span class="font-roboto font-normal text-sm capitalize">
                      {{ chapter.number || GENERAL_CONSTANTS.NOT_APPLICABLE }}
                    </span>
                  </td>
                  <td
                    class="ltr:border-r rtl:border-l border-primary-grey py-5 rtl:text-right pl-4 pr-2.5"
                  >
                    <span class="font-roboto font-normal text-sm capitalize">
                      {{ chapter.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
                    </span>
                  </td>
                  <td
                    class="ltr:border-r rtl:border-l border-primary-grey py-5 rtl:text-right pl-4 pr-2.5"
                  >
                    <span class="font-roboto font-normal text-sm capitalize">
                      {{ chapter.description || GENERAL_CONSTANTS.NOT_APPLICABLE }}
                    </span>
                  </td>
                  <td class="border-primary-grey w-10 px-5">
                    <UiCheckbox
                      :key="`chapter${chapter.isSelected}`"
                      :no-error="true"
                      :model-value="chapter.isSelected"
                      @change="updateSelectedChapterStatus(index, $event)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Pagination
        v-if="showPaginationForChapters"
        v-show="!isChaptersDataLoading"
        :record-limit="GENERAL_CONSTANTS.MODAL_RECORD_LIMIT"
        :max-range="paginationCounts(chaptersCount, GENERAL_CONSTANTS.MODAL_RECORD_LIMIT)"
        @filterRecord="fetchChaptersData"
      />
      <!-- Topics -->
      <div class="flex justify-between items-center">
        <Heading heading="Topics" />
      </div>
      <template v-if="isTopicsDataLoading">
        <Loader class="mt-20 mb-40" :content="true" />
      </template>
      <div v-else-if="!isTopicsDataLoading && isEmpty(currentTopicsList)">
        <NoRecordFound />
      </div>
      <div v-else class="bg-white my-5 relative rounded-lg border border-primary-grey">
        <div
          id="table-container"
          :class="[leftBlurArea, rightBlurArea]"
          class="overflow-x-auto rounded-lg"
          @scroll.passive="scrollDetect"
        >
          <div>
            <table class="w-full table-auto whitespace-nowrap">
              <thead class="bg-bright-gray">
                <tr class="text-left">
                  <th
                    v-for="head in topicsHeaders"
                    :key="head"
                    class="ltr:border-r border-primary-grey py-5 rtl:text-right pl-4 pr-2.5"
                  >
                    <span class="flex items-center gap-1">
                      {{ head }}
                    </span>
                  </th>
                  <th class="ltr:border-r border-primary-grey py-5 rtl:text-right pl-4 pr-2.5">
                    <span>
                      <UiCheckbox
                        :key="`topic${isAllTopicsSelected}`"
                        :no-error="true"
                        class="pl-1"
                        :model-value="isAllTopicsSelected"
                        @change="markAllTopicsSelected($event)"
                      />
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(topic, index) in currentTopicsList"
                  :key="`index-${index}`"
                  class="border-t border-primary-grey text-left"
                >
                  <td
                    class="ltr:border-r rtl:border-l border-primary-grey py-5 rtl:text-right pl-4 pr-2.5"
                  >
                    <span class="font-roboto font-normal text-sm capitalize">
                      {{ topic.number || GENERAL_CONSTANTS.NOT_APPLICABLE }}
                    </span>
                  </td>
                  <td
                    class="ltr:border-r rtl:border-l border-primary-grey py-5 rtl:text-right pl-4 pr-2.5"
                  >
                    <span class="font-roboto font-normal text-sm capitalize">
                      {{ topic.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
                    </span>
                  </td>
                  <td
                    class="ltr:border-r rtl:border-l border-primary-grey py-5 rtl:text-right pl-4 pr-2.5"
                  >
                    <span class="font-roboto font-normal text-sm capitalize">
                      {{ topic.description || GENERAL_CONSTANTS.NOT_APPLICABLE }}
                    </span>
                  </td>
                  <td
                    class="ltr:border-r rtl:border-l border-primary-grey py-5 rtl:text-right pl-4 pr-2.5"
                  >
                    <span class="font-roboto font-normal text-sm capitalize">
                      {{ topic.chapter_template.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
                    </span>
                  </td>
                  <td class="border-primary-grey w-10 px-5">
                    <UiCheckbox
                      :key="`topic${topic.isSelected}`"
                      :no-error="true"
                      :model-value="topic.isSelected"
                      @change="updateSelectedTopicStatus(index, $event)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Pagination
        v-if="showPaginationForTopics"
        v-show="!isTopicsDataLoading"
        :record-limit="GENERAL_CONSTANTS.MODAL_RECORD_LIMIT"
        :max-range="paginationCounts(topicsCount, GENERAL_CONSTANTS.MODAL_RECORD_LIMIT)"
        @filterRecord="fetchTopicsData"
      />
      <!-- Lesson -->
      <div class="flex justify-between items-center">
        <Heading heading="Lessons" />
      </div>
      <template v-if="isLessonDataLoading">
        <Loader class="mt-20 mb-40" :content="true" />
      </template>
      <div v-else-if="!isLessonDataLoading && isEmpty(currentLessonList)">
        <NoRecordFound />
      </div>
      <div v-else class="bg-white my-5 relative rounded-lg border border-primary-grey">
        <div
          id="table-container"
          :class="[leftBlurArea, rightBlurArea]"
          class="overflow-x-auto rounded-lg"
          @scroll.passive="scrollDetect"
        >
          <div>
            <table class="w-full table-auto whitespace-nowrap">
              <thead class="bg-bright-gray">
                <tr class="text-left">
                  <th
                    v-for="head in lessonHeaders"
                    :key="head"
                    class="ltr:border-r border-primary-grey py-5 rtl:text-right pl-4 pr-2.5"
                  >
                    <span class="flex items-center gap-1">
                      {{ head }}
                    </span>
                  </th>
                  <th class="ltr:border-r border-primary-grey py-5 rtl:text-right pl-4 pr-2.5">
                    <span>
                      <UiCheckbox
                        :key="`topic${isAllLessonsSelected}`"
                        :no-error="true"
                        class="pl-1"
                        :model-value="isAllLessonsSelected"
                        @change="markAllLessonSelected($event)"
                      />
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(lesson, index) in currentLessonList"
                  :key="`index-${index}`"
                  class="border-t border-primary-grey text-left"
                >
                  <td
                    class="ltr:border-r rtl:border-l border-primary-grey py-5 rtl:text-right pl-4 pr-2.5"
                  >
                    <span class="font-roboto font-normal text-sm capitalize">
                      {{ lesson.lesson_number || GENERAL_CONSTANTS.NOT_APPLICABLE }}
                    </span>
                  </td>
                  <td
                    class="ltr:border-r rtl:border-l border-primary-grey py-5 rtl:text-right pl-4 pr-2.5"
                  >
                    <span class="font-roboto font-normal text-sm capitalize">
                      {{ lesson.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
                    </span>
                  </td>
                  <td
                    class="ltr:border-r rtl:border-l border-primary-grey py-5 rtl:text-right pl-4 pr-2.5"
                  >
                    <span class="font-roboto font-normal text-sm capitalize">
                      {{ lesson.description || GENERAL_CONSTANTS.NOT_APPLICABLE }}
                    </span>
                  </td>
                  <td
                    class="ltr:border-r rtl:border-l border-primary-grey py-5 rtl:text-right pl-4 pr-2.5"
                  >
                    <span class="font-roboto font-normal text-sm capitalize">
                      {{ lesson.topic_template.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
                    </span>
                  </td>

                  <td class="border-primary-grey w-10 px-5">
                    <UiCheckbox
                      :key="`lesson${lesson.isSelected}`"
                      :no-error="true"
                      :model-value="lesson.isSelected"
                      @change="updateSelectedLessonStatus(index, $event)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Pagination
        v-if="showPaginationForLesson"
        v-show="!isLessonDataLoading"
        :record-limit="GENERAL_CONSTANTS.MODAL_RECORD_LIMIT"
        :max-range="paginationCounts(lessonCount, GENERAL_CONSTANTS.MODAL_RECORD_LIMIT)"
        @filterRecord="fetchLessonData"
      />
    </div>
    <div v-if="!isDataLoading" class="mt-5 flex justify-between">
      <div></div>
      <UiButton button="primary" :disabled="!isImportAllowed" @click="importTemplateToSection">
        Import
      </UiButton>
    </div>
  </div>
</template>

<script>
import FILTER_KEYS from '@src/constants/filter-constants.js'
import TitleWrapper from '@components/TitleWrapper.vue'
import scrollMixin from '@src/mixins/scroll-mixin'
import GENERAL_CONSTANTS from '@src/constants/general-constants.js'
import DetailsCard from '@src/components/UiElements/DetailCard.vue'
import Heading from '@components/Heading.vue'
import { mapState, mapActions } from 'vuex'
import UiCheckbox from '@components/UiElements/UiCheckbox.vue'
import { objectDeepCopy } from '@utils/generalUtil'
import { paginationRangeHandler } from '@src/components/BaseComponent/pagination.util.js'
import Loader from '@components/BaseComponent/Loader.vue'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import isEmpty from 'lodash/isEmpty'
import Pagination from '@components/BaseComponent/Pagination.vue'
import GeneralMixin from '@src/mixins/general-mixins.js'
import { formatDate } from '@utils/moment.util'
import { buildWhereQuery } from '@utils/filters/index'
import UiButton from '@src/components/UiElements/UIButton.vue'
export default {
  components: {
    TitleWrapper,
    DetailsCard,
    Heading,
    Loader,
    NoRecordFound,
    Pagination,
    UiCheckbox,
    UiButton,
  },
  mixins: [scrollMixin, GeneralMixin],
  data() {
    return {
      GENERAL_CONSTANTS,
      isEmpty,
      currentSyllabusTemplateId: '',
      actionList: [{ name: 'Edit' }, { name: 'Delete' }],
      isDataLoading: false,
      // Chapters related state
      chaptersHeaders: ['Chapter No', 'Title', 'Description'],
      currentChaptersList: [],
      chaptersList: {},
      allChapters: null,
      chaptersCount: 0,
      isChaptersDataLoading: false,
      showPaginationForChapters: false,
      selectedChaptersIds: [],
      isAllChaptersSelected: undefined,

      // Topics related state
      topicsHeaders: ['Topic No', 'Title', 'Description', 'Chapter Template'],
      currentTopicsList: [],
      topicsList: {},
      allTopics: null,
      topicsCount: 0,
      isTopicsDataLoading: false,
      showPaginationForTopics: false,
      selectedTopicsIds: [],
      isAllTopicsSelected: undefined,

      // Lesson related state
      lessonHeaders: ['Lesson No', 'Title', 'Description', 'Topic Template'],
      currentLessonList: [],
      lessonList: {},
      allLessons: null,
      lessonCount: 0,
      isLessonDataLoading: false,
      showPaginationForLesson: false,
      isAllLessonsSelected: undefined,
      // Template Details
      templateDetails: {},
      isImportAllowed: false,
    }
  },

  computed: {
    currentTemplateWhereQuery() {
      return buildWhereQuery(
        FILTER_KEYS.EQUAL,
        'subject_template_id',
        this.currentSyllabusTemplateId,
      )
    },

    sectionId() {
      return this.$route.query.sectionId
    },

    classId() {
      return this.$route.query.classId
    },
    subjectId() {
      return this.$route.query.subjectId
    },
    ...mapState({
      currentCampusScope: (state) => state?.layout?.currentCampusScope,
    }),
  },
  watch: {
    currentCampusScope: {
      handler() {
        this.$router?.push({ name: 'syllabus-template' })
      },
    },
    currentChaptersList: {
      deep: true,
      handler(list) {
        this.isImportAllowed = list.some((item) => item.isSelected)
        this.isAllChaptersSelected = list.every((chapter) => !!chapter.isSelected)
      },
    },
    currentTopicsList: {
      deep: true,
      handler(list) {
        this.isAllTopicsSelected = list.every((topic) => !!topic.isSelected)
      },
    },
    currentLessonList: {
      deep: true,
      handler(list) {
        this.isAllLessonsSelected = list.every((lesson) => !!lesson.isSelected)
      },
    },
  },
  created() {
    this.currentSyllabusTemplateId = this.$route.params.id
  },
  mounted() {
    this.fetchRecords()
  },
  methods: {
    fetchRecords() {
      this.isDataLoading = true
      let range = paginationRangeHandler(_, GENERAL_CONSTANTS.MODAL_RECORD_LIMIT)
      let payload = {
        ...range,
        $where: this.currentTemplateWhereQuery,
      }
      const templateDetails = this.getSyllabusTemplateDetail(this.currentSyllabusTemplateId)
      const chapters = this.getSyllabusTemplateChapterList(payload)

      Promise.all([chapters, templateDetails]).then((values) => {
        const { meta: chapterMeta, records: chaptersRecords } = values[0]
        this.templateDetails = this.transformDetails(values[1])

        this.currentChaptersList = chaptersRecords
        this.chaptersList = { 0: chaptersRecords }
        this.chaptersCount = chapterMeta.total_records
        this.showPaginationForChapters = this.chaptersCount > GENERAL_CONSTANTS.MODAL_RECORD_LIMIT

        this.isDataLoading = false
      })
    },

    transformDetails(details) {
      return {
        title: details.title,
        syllabusProvider: details.syllabus_provider.title,
        importCount: details.import_count,
        updatedAt: formatDate(details.updated_at),
        edition: details.edition,
      }
    },

    async fetchChaptersData(range) {
      this.isChaptersDataLoading = true
      let paginationRange = paginationRangeHandler(range, GENERAL_CONSTANTS.MODAL_RECORD_LIMIT)
      let payload = {
        ...paginationRange,
        $where: {
          ...this.currentTemplateWhereQuery,
        },
      }

      let pageNo = this.getPageNumberWithRange(paginationRange)
      let isChapterListAlreadyExisted = this.chaptersList.hasOwnProperty(`${pageNo}`)

      const { meta, records } = await this.getSyllabusTemplateChapterList(payload)

      if (isChapterListAlreadyExisted) this.currentChaptersList = this.chaptersList[`${pageNo}`]
      else {
        this.currentChaptersList = records
        this.chaptersList = { ...this.chaptersList, ...{ [pageNo]: records } }
      }
      this.chaptersCount = meta.total_records
      this.showPaginationForChapters = this.chaptersCount > GENERAL_CONSTANTS.MODAL_RECORD_LIMIT
      this.isChaptersDataLoading = false
    },

    updateSelectedChapterStatus(idx, e) {
      this.currentChaptersList[idx].isSelected = e.status ? e.status : undefined
      this.resetTopicsData()
      this.resetLessonData()
      this.fetchUpdatedTopics()
    },
    markAllChaptersSelected(e) {
      this.currentChaptersList.map((chapter) => {
        chapter.isSelected = e.status ? e.status : undefined
      })
      this.resetTopicsData()
      this.resetLessonData()
      this.fetchUpdatedTopics()
    },

    fetchUpdatedTopics() {
      this.selectedChaptersIds = []
      let chapters = Object.values(this.chaptersList).flat()

      chapters.forEach((chapter) => {
        if (chapter.isSelected) this.selectedChaptersIds.push(chapter.id)
      })
      this.topicsList = {}
      this.fetchTopicsData()
    },

    async fetchTopicsData(range) {
      this.isTopicsDataLoading = true
      let paginationRange = paginationRangeHandler(range, GENERAL_CONSTANTS.MODAL_RECORD_LIMIT)
      let payload = {
        ...paginationRange,
        $where: {
          ...this.currentTemplateWhereQuery,
          ...buildWhereQuery(FILTER_KEYS.IN, 'chapter_template_ids', this.selectedChaptersIds),
        },
      }

      let pageNo = this.getPageNumberWithRange(paginationRange)
      let isTopicsListAlreadyExisted = this.topicsList.hasOwnProperty(`${pageNo}`)

      const { meta, records } = await this.getSyllabusTemplateTopicList(payload)
      if (isTopicsListAlreadyExisted) this.currentTopicsList = this.topicsList[`${pageNo}`]
      else {
        this.currentTopicsList = records
        this.topicsList = { ...this.topicsList, ...{ [pageNo]: records } }
      }
      this.topicsCount = meta.total_records
      this.showPaginationForTopics = this.topicsCount > GENERAL_CONSTANTS.MODAL_RECORD_LIMIT
      this.isTopicsDataLoading = false
    },

    updateSelectedTopicStatus(idx, e) {
      this.currentTopicsList[idx].isSelected = e.status ? e.status : undefined
      this.resetLessonData()
      this.fetchUpdatedLesson()
    },
    markAllTopicsSelected(e) {
      this.currentTopicsList.map((topic) => {
        topic.isSelected = e.status ? e.status : undefined
      })
      this.resetLessonData()
      this.fetchUpdatedLesson()
    },
    fetchUpdatedLesson() {
      this.selectedTopicsIds = []
      let topics = Object.values(this.topicsList).flat()

      topics.forEach((topic) => {
        if (topic.isSelected) this.selectedTopicsIds.push(topic.id)
      })
      this.lessonList = {}
      this.fetchLessonData()
    },

    async fetchLessonData(range) {
      this.isLessonDataLoading = true
      let paginationRange = paginationRangeHandler(range, GENERAL_CONSTANTS.MODAL_RECORD_LIMIT)
      let payload = {
        ...paginationRange,
        $where: {
          ...this.currentTemplateWhereQuery,
          ...buildWhereQuery(FILTER_KEYS.IN, 'topic_template_ids', this.selectedTopicsIds),
        },
      }

      let pageNo = this.getPageNumberWithRange(paginationRange)
      let isLessonListAlreadyExisted = this.lessonList.hasOwnProperty(`${pageNo}`)

      const { meta, records } = await this.getSyllabusTemplateLessonList(payload)

      if (isLessonListAlreadyExisted) this.currentLessonList = this.lessonList[`${pageNo}`]
      else {
        this.currentLessonList = records
        this.lessonList = { ...this.lessonList, ...{ [pageNo]: records } }
      }

      this.lessonCount = meta.total_records
      this.showPaginationForLesson = this.lessonCount > GENERAL_CONSTANTS.MODAL_RECORD_LIMIT
      this.isLessonDataLoading = false
    },

    updateSelectedLessonStatus(idx, e) {
      this.currentLessonList[idx].isSelected = e.status ? e.status : undefined
    },
    markAllLessonSelected(e) {
      this.currentLessonList.map((lesson) => {
        lesson.isSelected = e.status ? e.status : undefined
      })
    },

    embedRelatedLessonInTopics() {
      this.allTopics = Object.values(this.topicsList).flat()
      this.allLessons = Object.values(this.lessonList).flat()

      this.allTopics.forEach((topic) => {
        topic.selectedLessons = {}
        this.allLessons.forEach((lesson) => {
          if (lesson.isSelected && lesson.topic_template.id === topic.id) {
            topic.selectedLessons[lesson.id] = {}
          }
        })
      })
    },

    embedRelatedTopicsInChapters() {
      this.allChapters = Object.values(this.chaptersList).flat()

      this.allChapters.forEach((chapter) => {
        chapter.selectedTopics = {}
        this.allTopics.forEach((topic) => {
          if (topic.isSelected && topic.chapter_template.id === chapter.id) {
            chapter.selectedTopics[topic.id] = topic.selectedLessons
          }
        })
      })
    },

    transformSelectedList(selectedList) {
      let list = {}
      selectedList.forEach((chapter) => {
        list[chapter.id] = chapter.selectedTopics
      })
      return list
    },

    importTemplateToSection() {
      let finalPayload = {
        id: this.currentSyllabusTemplateId,
        section_id: this.sectionId,
        class_id: this.classId,
        subject_id: this.subjectId,
      }

      this.embedRelatedLessonInTopics()
      this.embedRelatedTopicsInChapters()

      let selectedList = this.allChapters.filter((chapter) => {
        return chapter.isSelected
      })

      finalPayload = {
        ...finalPayload,
        chapter_templates: this.transformSelectedList(selectedList),
      }

      this.importSyllabusTemplateToSection(finalPayload).then((response) => {
        this.$store.commit(
          'toast/NEW',
          {
            type: 'success',
            message: response?.message || 'Import started',
          },
          { root: true },
        )
        this.$router?.push({ name: 'subject-syllabus-template' })
      })
    },

    getPageNumberWithRange(paginationRange) {
      return paginationRange.skip / GENERAL_CONSTANTS.MODAL_RECORD_LIMIT
    },
    resetChaptersData() {
      this.currentChaptersList = []
      this.chaptersList = {}
      this.chaptersCount = 0
      this.showPaginationForChapters = false
    },
    resetTopicsData() {
      this.currentTopicsList = []
      this.topicsList = {}
      this.topicsCount = 0
      this.showPaginationForTopics = false
    },
    resetLessonData() {
      this.currentLessonList = []
      this.lessonList = {}
      this.lessonCount = 0
      this.showPaginationForLesson = false
    },

    ...mapActions('syllabusmanager', [
      'getSyllabusTemplateChapterList',
      'getSyllabusTemplateLessonList',
      'getSyllabusTemplateTopicList',
      'getSyllabusTemplateDetail',
      'importSyllabusTemplateToSection',
    ]),
  },
}
</script>
